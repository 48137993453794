export const privacyString = (
  str: string,
  start: number,
  end: number,
  char: string = "*"
) => {
  if (!str) return "";
  const len = str.length - start - end;
  let result = "";
  for (let i = 0; i < len; i++) {
    result += char;
  }
  return str.substring(0, start) + result + str.substring(str.length - end);
};

export const hideString = (
  str: string,
  start: number,
  end: number,
  char: string = "*"
) => {
  if (!str) return "";
  return (
    str.substring(0, start) +
    new Array(8).fill(char).join("") +
    str.substring(str.length - end)
  );
};

